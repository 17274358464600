var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"Question-container",style:(_vm.questionContainerStyle),on:{"click":_vm.slotCardSelected}},[_c('div',{staticClass:"Question-body"},[_c('div',{staticClass:"row-div"},[_c('div',{staticClass:"row-info-div"},[_c('div',{staticClass:"title-div"},[_c('div',{staticClass:"row py-2"},[_c('div',{staticClass:"pr-1 pt-1 ml-2"},[_c('strong',[_vm._v(_vm._s(_vm.Question.order))]),_vm._v(". ")]),_c('div',{staticClass:"text-left pt-1 rich-text-content",domProps:{"innerHTML":_vm._s(_vm.Question.title)}})])])]),_c('div',{staticClass:"vl"}),_c('div',{staticClass:"footer-buttons-div"},[_c('div',{staticClass:"type-div pb-2"},[_c('strong',[_vm._v(_vm._s(_vm.questionType ? _vm.questionType.value : "-"))])])])]),_c('hr'),_c('ImagesContainer',{attrs:{"Images":_vm.topQuestionImages,"allows_crud":false}}),(_vm.show_description)?_c('div',{staticClass:"content-div"},[_c('p',{staticClass:"rich-text-content",domProps:{"innerHTML":_vm._s(_vm.Question.description)}})]):_vm._e(),(_vm.Question.question_type <= 2)?[(isNaN(_vm.redaction_question_answer.id))?[_vm._v(" Sin respuesta... ")]:(
          !_vm.redaction_question_answer.answer ||
          _vm.redaction_question_answer.answer.length == 0
        )?[_vm._v(" Sin respuesta... ")]:[_c('div',[_c('strong',[_vm._v("Respuesta: ")]),_vm._v(_vm._s(_vm.redaction_question_answer.answer)+" ")])]]:_vm._e(),_c('ImagesContainer',{attrs:{"Images":_vm.bottomQuestionImages,"allows_crud":false}}),_c('hr'),_c('div',{staticClass:"row-div"},[_c('div',{staticClass:"row-info-div pt-1"},[_c('div',{staticClass:"px-2"},[_vm._v("Puntaje: "+_vm._s(_vm.Question.score))])]),_c('div',{staticClass:"vl"}),_c('div',{staticClass:"footer-buttons-div"},[(_vm.student_id && _vm.institution)?_c('div',{staticClass:"mb-1"},[_vm._v(" Puntaje Obtenido: "),(
              _vm.institution.actual_period != null &&
              _vm.institution.actual_period.id == _vm.period_id
            )?[_c('StyledInputNumber',{staticStyle:{"width":"80px"},on:{"input":_vm.patchAnswerScore},model:{value:(_vm.manual_score.score),callback:function ($$v) {_vm.$set(_vm.manual_score, "score", $$v)},expression:"manual_score.score"}}),(!_vm.$v.redaction_question_answer.score.between)?_c('div',{staticClass:"error"},[_vm._v(" El puntaje debe estar entre "+_vm._s(_vm.$v.redaction_question_answer.score.$params.between.min)+" y "+_vm._s(_vm.$v.redaction_question_answer.score.$params.between.max)+" ")]):_vm._e()]:[_vm._v(" "+_vm._s(_vm.manual_score.score)+" ")]],2):_vm._e()])]),_c('b-button',{directives:[{name:"b-toggle",rawName:"v-b-toggle",value:(`collapse-feedback-${_vm.Question.id}`),expression:"`collapse-feedback-${Question.id}`"}],staticClass:"btn-feedback",attrs:{"variant":"none","size":"sm"}},[_vm._v(" Retroalimentar ")]),(_vm.institution)?_c('b-collapse',{attrs:{"id":`collapse-feedback-${_vm.Question.id}`}},[_c('b-form-textarea',{attrs:{"size":"sm","rows":"3","disabled":!_vm.student_id ||
          (_vm.institution.actual_period != null &&
            _vm.institution.actual_period.id != _vm.period_id),"max-rows":"6"},on:{"input":_vm.patchAnswerScore},model:{value:(_vm.manual_score.feedback),callback:function ($$v) {_vm.$set(_vm.manual_score, "feedback", $$v)},expression:"manual_score.feedback"}})],1):_vm._e()],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }